import { DOCS } from '~/constants/data'

function docExistsForYear(year: number): year is keyof typeof DOCS {
  return year in DOCS
}

export default (year: number) => {
  if (docExistsForYear(year) && !process.server) {
    window.open(DOCS[year], '_blank')
  }
}
